@import 'mixins';

//FontStyles
$font-family: 'Helvetica Neue', sans-serif;

//FontSizes
$font-size-xxs: rem(10);
$font-size-xs: rem(12);
$font-size-s: rem(14);
$font-size-regular: rem(16);
$font-size-l: rem(22);
$font-size-xl: rem(30);
$font-size-xxl: rem(48);

//LineHeights
$lineheight-xs: rem(16);
$lineheight-s: rem(20);
$lineheight-regular: rem(24);
$lineheight-l: rem(32);
$lineheight-xl: rem(44);
$lineheight-xxl: rem(60);

//FontWeights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

//FontColors
$font-color-regular: #ffffff;
$font-color-light: #313131;

//Colors
$color-red-darker: #741940;
$color-red-dark: #aa2417;
$color-red: #e0301e;
$color-red-light: #e86153;
$color-red-lighter: #f7c8c4;
$color-black: #000000;
$color-white: #ffffff;

//PwC Brand Colors
$color-background: #000000;
$color-pwc-white: #cccccc;

$color-gold: #fcb300;
$color-gold-hex: 252, 179, 0;
$color-orange-dark: #ce4a1a;
$color-orange: #e45c2b;
$color-orange-light: #e45c2b;
$color-orange-lighter: #e45c2b;

$color-grey-darker: #141414;
$color-grey-dark-alt: #1c1c1c;
$color-grey-dark: #2d2d2d;
$color-grey-alt: #707070;
$color-grey: #bbb;
$color-grey-light: #dedede;
$color-grey-lighter: #f2f2f2;

$color-green: #22992e;
$color-blue: #3e87ba;

//Padding and Margins
$padding-double: rem(40); // equal to 40px
$padding-standard: rem(20); // equal to 20px
$padding-threequarters: rem(15); // equal to 15px
$padding-half: rem(10); // equal to 10px
$padding-quarter: rem(5); // equal to 5px

$margin-double: rem(40); // equal to 40px
$margin-standard: rem(20); // equal to 20px
$margin-threequarters: rem(15); // equal to 15px
$margin-half: rem(10); // equal to 10px
$margin-quarter: rem(5); // equal to 5px

//New Padding and Margin Spacing
$s25: rem(100); // equal to 100px
$s24: rem(96); // equal to 96px
$s23: rem(92); // equal to 92px
$s22: rem(88); // equal to 88px
$s21: rem(84); // equal to 84px
$s20: rem(80); // equal to 80px
$s19: rem(76); // equal to 76px
$s18: rem(72); // equal to 72px
$s17: rem(68); // equal to 68px
$s16: rem(64); // equal to 64px
$s15: rem(60); // equal to 60px
$s14: rem(56); // equal to 56px
$s13: rem(52); // equal to 52px
$s12: rem(48); // equal to 48px
$s11: rem(44); // equal to 44px
$s10: rem(40); // equal to 40px
$s9: rem(36); // equal to 36px
$s8: rem(32); // equal to 32px
$s7: rem(28); // equal to 28px
$s6: rem(24); // equal to 24px
$s5: rem(20); // equal to 20px
$s4: rem(16); // equal to 16px
$s3: rem(12); // equal to 12px
$s2: rem(8); // equal to 8px
$s1: rem(4); // equal to 4px
$s3px: rem(3); // equal to 3px
$s2px: rem(2); // equal to 2px
$s1px: rem(1); // equal to 1px

//Borders
$border-standard: rem(1) solid #2d2d2d;
$border-size-standard: rem(2);
$border-radius: rem(4);
$border-radius-half: rem(2);

//Forms
$input-color: #ffffff;
$label-color: #ffffff;
$label-color-focused: #949494;
$input-alert-color: #ffbf1f;
$input-background: #2d2d2d;
$input-border: #707070;
$input-radio-border: $input-border;
$input-radio-border-size: 0.0625rem;
$input-radio-size: rem(24);

//Menus
$modal-color: #464646;
$modal-border: rem(1) solid #464646;
$modal-border-color: #464646;
$menu-icon-color: #7d7d7d;

//Tables
$table-header-color: #979797;
$table-shadow-color: #464646;
$table-shadow: 0 rem(1, true) 0 0 #464646;
$table-shadow-light: 0 rem(1, true) 0 0 #979797;
$table-background-color: #1e1e1e;
$table-border-radius: rem(2);

//Navigation
$navbar-color: #1e1e1e;
$navbar-height: $s16;
