@import '../../../styles/variables';

.inline-message {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid $color-grey-lighter;
  line-height: 1.2;

  &--failure {
    border-color: $color-orange;
    color: $color-orange;
  }

  &--success {
    border-color: $color-green;
    color: $color-green;
  }
}