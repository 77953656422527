.header {
  display: flex;
  padding: 16px;
  background-color: $color-black;
  height: auto;

  & > * {
    color: $color-orange;
  }

  &__platform-name {
    color: $color-white;
    font-size: 0.875rem;
  }
}