@import 'variables';

* {
  font-family: $font-family;
  font-weight: $font-weight-regular;
}

body {
  background: var(--color-grey-lighter);
  font-size: var(--s4);
  min-width: rem(320);
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

main {
  padding: 0 var(--s4) var(--s4);
}

h2 {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  line-height: $lineheight-l;
}

h3 {
  font-size: $font-size-l;
  font-weight: $font-weight-bold;
  line-height: $lineheight-l;
}

.ppt-mini-loader-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
}

.status-message {
  margin: var(--s4);
}

.status-message-item {
  margin-bottom: var(--s4);
  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  -webkit-appearance: button;
  border: none;
}

.placeholder {
  overflow-x: auto;
}

button {
  .mini-loader-contain {
    .path {
      stroke: var(--color-white) !important;
    }
    svg.spinner {
      width: var(--s5);
      height: var(--s5);
    }
  }
}

.success-message {
  display: flex;
  align-items: flex-start;
  font-size: var(--s4);
  margin: var(--s4) 0;

  &_icon-wrapper {
    padding-top: var(--s1);
    svg {
      width:calc(var(--s4) + var(--s2px));
      height:calc(var(--s4) + var(--s2px))
     }
  }

  

  p {
    margin-top: 0;
    margin-bottom: var(--s2);
    line-height: var(--s5);
  }

  &__body {
    padding: 0 var(--s2);
  }
}

.ppt-card.card{
  background-color: var(--color-grey-light);
  border: var(--s1px) solid var(--color-grey);
}

.ppt-card {
  position: relative;
  justify-content: center;
  display: flex;
  
  transition: all 0.5s ease;

  min-height: calc(var(--s18) + var(--s18));

  .ppt-object-wrapper {
    svg {
      max-width: 100%;
      height: calc(var(--s18) + var(--s18));
    }
  }

  &__icon-wrapper{
    padding-top: var(--s1);
    svg {
      width:calc(var(--s4) + var(--s2px));
      height:calc(var(--s4) + var(--s2px))
     }

  }

  &__success {
    display: flex;
    margin-bottom: var(--s4);
    align-items: center;

    &.error {
      align-items: start;
    }

    &__icon-wrapper {
      padding: var(--s1) var(--s1) 0 var(--s1);

      &.error {
        padding: var(--s1) var(--s2) 0 0;
      }

      svg {
        width: calc(var(--s4) + var(--s2px));
        height: calc(var(--s4) + var(--s2px))
      }

    }
    
    p {
        display: inline-block;
        margin: 0;

      &.error {
        font-size: var(--font-size-s);
        color: var(--color-red-error-light);

        a {
          color: var(--color-red-error-light);
        }
      }
    }
  }

  &--active {
    border: var(--s2px) solid green;
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #d04a0242;
    border: var(--s2px) solid var(--color-orange);

    button {
      display: flex;
      align-items: center;
      font-size: var(--s4);
      padding: var(--s3) 18px;
    }

    .icon {
      margin-right: calc(var(--s2));

      path.fill {
        fill: white;
      }
    }
  }
}

.refresh-icon-container {
  position: absolute;
  top: var(--margin-threequarters );
  right: var(--font-size-xl);
}

.refresh-icon {
  cursor: pointer;

  svg {
    width: var(--s5);

    .fill {
      fill: var(--color-orange);
    }
    .outline {
      fill: var(--color-orange);
    }
  }
}


@import 'global/header';
@import 'global/tabs';
@import 'elements/button';
@import 'elements/card';
@import 'elements/forms';
