@function rem($size, $neg: false) {
  $remSize: $size / 16;

  @if $neg==true {
    @return -#{$remSize}rem;
  } @else {
    @return #{$remSize}rem;
  }
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}
