.card {
  background: var(--color-white);
  padding: var(--s6);
  margin-bottom: var(--s4);
  box-shadow: $s1px $s1px $color-grey-light;

  &-title {
    margin-top: 0;
  }

  &-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: var(--s4);

    .button:not(:last-child) {
      margin-right: var(--s4);
    }
  }

  .select-component .select-container .select-element select {
    width: 100%;
  }
}
