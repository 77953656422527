form {
  label {
    display: block;
    margin-bottom: 8px;
  }
}

html[data-theme='light'] {
  .select-container select {
    background-color: var(--color-grey-lighter);
    color: var(--color-grey-dark);
    border-color: var(--color-grey-light);
    border-radius: var(--border-radius);
  }
}