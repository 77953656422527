@import '../variables';

html[data-theme='light'] {
  .button:disabled {
    background-color: $color-grey-lighter;
    color: $color-grey;
  }
  .button-orange:disabled {
    background-color: var(--color-orange-theme);
  }
}