@import '../../../styles/variables';

.insert-library-item {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001; // get on top of CKEditor menu bar
  background-color: $color-black;
}

html[data-theme='light'] {
  .insert-library-item {
    background-color: $color-grey-lighter;
  }
}