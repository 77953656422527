@import '../../../styles/variables';

.loading {
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $color-grey-alt;
  }
}