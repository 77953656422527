@import '../../../styles/variables';

.ck-editor {
  * {
    color: $color-white;
  }

  strong {
    font-weight: bold;
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--input-background) !important;
  max-height: 360px;
}

html[data-theme='light'] {
  .ck-editor {
    * {
      color: $color-black;

      .item-not-found {
        padding: 16px;
        margin: 16px 0;
        border: 1px solid $color-grey-lighter;
        line-height: 1.2;
        border-color: $color-orange;
        color: $color-orange;
      }
    }

    strong {
      font-weight: bold;
    }
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background-color: $color-white !important;
  }
}
