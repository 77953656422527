.copy-error-message {
  position: relative;
  justify-content: center;
  transition: all 0.5s ease;
  display: flex;
  margin-bottom: var(--s4);
  align-items: center;
  min-height: calc(var(--s18) + var(--s18));

  &__success {
    display: flex;
    margin-bottom: var(--s4);
    align-items: center;
    margin: var(--s2);

    &.error {
      align-items: start;
    }

    &__icon-wrapper {
      padding: var(--s1) var(--s1) 0 var(--s1);

      &.error {
        padding: var(--s1) var(--s2) 0 0;
      }

      svg {
        width: calc(var(--s4) + var(--s2px));
        height: calc(var(--s4) + var(--s2px));
      }
    }

    p {
      display: inline-block;
      margin: 0;

      &.error {
        font-size: var(--font-size-s);
        color: var(--color-red-error-light);

        a {
          color: var(--color-red-error-light);
          cursor: pointer;
        }
      }
    }
  }
}
