.tabs {
  border-bottom: 1px solid $color-grey-dark;
  display: flex;

  &-tab {
    color: var(--color-grey);
    padding: 10px 0;
    text-decoration: none;
    width: 50%;
    transition: color var(--transition);

    .icon {
      path.fill {
        fill: transparent;
        transition: all var(--transition);
      }

      path {
        fill: var(--color-grey);
        transition: fill var(--transition);
      }
    }

    &:hover,
    &--active {
      border-bottom: 2px solid var(--color-orange);
      color: var(--project-color-main);

      .icon {
        path.fill {
          fill: var(--project-color-main);
          transition: fill var(--transition);
        }

        path {
          fill: transparent;
          transition: all var(--transition);
        }
      }
    }
  }

  &-tabcontent {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--ingestion {
      border-right: var(--s1px) solid var(--color-grey-dark);
    }

    .icon {
      margin-right: 10px;
      height: 100%;
    }

    span {
      line-height: 30px;
      font-size: 16px;
    }
  }
}
